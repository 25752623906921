import { Dialog, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { useCallback, useState } from "react"
import { CameraConfig, Site, Unit } from "../../api/Customer"
import { Playback } from "../../api/Video"
import { Flags } from "../../hooks/flags"
import { NumberChoice } from "../common/TimelineChoice"
import { LabelledCamera } from "./LabelledCamera"

export interface CameraDialogProps {
    site: Site
    unit: Unit
    camera: CameraConfig
    playback: Playback
    toggledHistory: boolean
    small: boolean
    initialShowSettings?: boolean
    initialShowPTZ?: boolean
    pinned: boolean
    motionAlarms: Flags
    displayUnit?: boolean
    hasEvents: boolean
    eventsPlayback: boolean
    window: NumberChoice
    onWindowChange?: (window: NumberChoice) => void
    onSetEventsPlayback: (value: boolean) => void
    onClose: () => void
    onPlaybackChange: (playback: Playback) => void
    onHistory: () => void
    onPin?: () => void
}

export function CameraDialog(props: CameraDialogProps) {
    const {
        site,
        unit,
        camera,
        playback,
        toggledHistory,
        small,
        initialShowSettings,
        initialShowPTZ,
        pinned,
        motionAlarms,
        displayUnit,
        hasEvents,
        eventsPlayback,
        window,
        onWindowChange,
        onSetEventsPlayback,
        onClose,
        onPlaybackChange,
        onHistory,
        onPin,
    } = props

    const [aspectRatio, setAspectRatio] = useState(16 / 9)

    const onResultion = useCallback((width: number, height: number) => {
        setAspectRatio(width / height)
    }, [])

    const theme = useTheme()
    return (
        <Dialog
            aria-labelledby="camera-dialog-title"
            onClose={onClose}
            open
            maxWidth="xl"
            sx={{ overflow: "hidden", borderRadius: 0 }}
        >
            <Box
                overflow="hidden"
                width={`min(${theme.breakpoints.values.xl}px, min(calc(100vw - 64px), calc(100vh - 80px) * ${aspectRatio}))`}
            >
                <LabelledCamera
                    site={site}
                    unit={unit}
                    camera={camera}
                    playback={playback}
                    small={small}
                    toggledHistory={toggledHistory}
                    embedHistory
                    initialShowSettings={initialShowSettings}
                    initialShowPTZ={initialShowPTZ}
                    pinned={pinned}
                    motionAlarms={motionAlarms}
                    displayUnit={displayUnit}
                    hasEvents={hasEvents}
                    eventsPlayback={eventsPlayback}
                    window={window}
                    onWindowChange={onWindowChange}
                    onSetEventsPlayback={onSetEventsPlayback}
                    onClick={onClose}
                    onHistory={onHistory}
                    onPlaybackChange={onPlaybackChange}
                    onPin={onPin}
                    onResolution={onResultion}
                />
            </Box>
        </Dialog>
    )
}
