import { Typography } from "@mui/material"
import {
    Configuration,
    JsonError,
    SelfServiceLoginFlow,
    SelfServiceSettingsFlow,
    UiContainer,
    V0alpha2Api,
} from "@ory/client"
import { AxiosError } from "axios"
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { NavigateFunction } from "react-router-dom"
import { useDocumentVisibilityState } from "rooks"
import { WhoAmI } from "../api/WhoAmI"
import { http, noSnackBar } from "../backend/request"
import { request } from "../config/headers"
import { hookIntervalAndRun, Minute } from "../config/time"
import { endpointURL, oryEndpoint, retainConfig, urlTo } from "../config/urls"

const authnCheckPeriod = 1 * Minute

type errorsCallback = (messages: string[]) => void

interface AuthContextType {
    whoAmI?: WhoAmI
    expired: boolean
    loading: boolean
    login: (
        email: string,
        password: string,
        showErrors: errorsCallback,
        navigate: NavigateFunction,
        redirectTo: string
    ) => void
    setPassword: (flow: string, password: string, showErrors: errorsCallback, navigate: NavigateFunction) => void
    startRecovery: (email: string, showErrors: errorsCallback, navigate: NavigateFunction) => void
    logout: (navigate: NavigateFunction) => void
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [whoAmI, setWhoAmI] = useState<WhoAmI>()
    const [loading, setLoading] = useState<boolean>(false)
    const [expired, setExpired] = useState<boolean>(false)
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true)

    const extractCSRFToken = (ui: UiContainer) => {
        const csrfNode = ui.nodes.find((n) => "name" in n.attributes && n.attributes.name === "csrf_token")
        if (!csrfNode || !("value" in csrfNode.attributes)) {
            return undefined
        }
        return csrfNode.attributes.value
    }

    const extractMessages = (ui: UiContainer) => {
        const mainMessages: string[] = ui.messages ? ui.messages.map((m) => m.text) : []
        const nodeMessages: string[] = ui.nodes.flatMap((m) => m.messages).map((m) => m.text)
        return mainMessages.concat(nodeMessages)
    }

    const ory = useMemo(() => new V0alpha2Api(new Configuration({ basePath: oryEndpoint })), [])

    useEffect(() => {
        http<WhoAmI>("Loading current user", endpointURL("whoami"), noSnackBar, request)
            .then(setWhoAmI)
            .catch(() => {})
            .finally(() => setLoadingInitial(false))
    }, [])

    const pageVisibility = useDocumentVisibilityState()
    const isVisible = useMemo(() => pageVisibility === "visible", [pageVisibility])

    useEffect(() => {
        if (expired || !isVisible || !whoAmI) {
            return
        }
        return hookIntervalAndRun(
            () =>
                http<void>("Checking user authn", endpointURL("authn"), noSnackBar, request)
                    .catch((e) => {
                        if (String(e).includes("(HTTP 401)")) {
                            setExpired(true)
                        }
                    })
                    .finally(() => {}),
            authnCheckPeriod
        )
    }, [expired, isVisible, whoAmI])

    const login = useCallback(
        (
            email: string,
            password: string,
            showErrors: errorsCallback,
            navigate: NavigateFunction,
            redirectTo: string
        ) => {
            // TODO(osery): Ory is actually sending UI information that should be used to render the
            // login form, e.g., which social sign-ins are supported. This is now ignored, but should be
            // part of the login form later on.

            ory.initializeSelfServiceLoginFlowForBrowsers()
                .then((response) =>
                    ory.submitSelfServiceLoginFlow(response.data.id, {
                        method: "password",
                        identifier: email,
                        password: password,
                        csrf_token: extractCSRFToken(response.data.ui),
                    })
                )
                .then((response) => {
                    console.log("login successful, reloading actor", response.data)
                    setLoading(true)
                })
                .then(() => http<WhoAmI>("Reloading current user", endpointURL("whoami"), noSnackBar, request))
                .then((who) => {
                    setWhoAmI(who)
                    navigate(retainConfig(urlTo(redirectTo)), { replace: true })
                })
                .catch((err: AxiosError<SelfServiceLoginFlow | JsonError>) => {
                    const data = err.response?.data
                    console.log("login failed", err)
                    if (data && "ui" in data) {
                        showErrors(extractMessages(data.ui))
                    } else if (data && "error" in data && data.error.reason) {
                        showErrors([data.error.reason])
                    } else {
                        showErrors([err.message])
                    }
                })
                .catch((err) => {
                    console.log("login failed", err)
                    showErrors(["Login failed. Please try again."])
                })
                .finally(() => setLoading(false))
        },
        [ory]
    )

    const setPassword = useCallback(
        (flow: string, password: string, showErrors: errorsCallback, navigate: NavigateFunction) => {
            ory.getSelfServiceSettingsFlow(flow)
                .then((response) =>
                    ory.submitSelfServiceSettingsFlow(flow, {
                        method: "password",
                        password: password,
                        csrf_token: extractCSRFToken(response.data.ui),
                    })
                )
                .then(
                    // All good, navigate to the home page.
                    () => navigate(retainConfig({ pathname: "/" }))
                )
                .catch((err: AxiosError<SelfServiceSettingsFlow | JsonError>) => {
                    const data = err.response?.data
                    console.log("setting password failed", err)
                    if (data && "ui" in data) {
                        showErrors(extractMessages(data.ui))
                    } else if (data && "error" in data && data.error.reason) {
                        showErrors([data.error.reason])
                    } else {
                        showErrors([err.message])
                    }
                })
                .catch((err) => {
                    console.log("setting password failed for unknown reason", err)
                    showErrors(["Setting password has failed. Please try again."])
                })
        },
        [ory]
    )

    const startRecovery = useCallback(
        (email: string, showErrors: errorsCallback, navigate: NavigateFunction) => {
            ory.initializeSelfServiceRecoveryFlowForBrowsers()
                .then((response) =>
                    ory.submitSelfServiceRecoveryFlow(response.data.id, {
                        method: "link",
                        email: email,
                        csrf_token: extractCSRFToken(response.data.ui),
                    })
                )
                .then((response) => {
                    console.log("recovery request successful, reloading actor", response.data)
                    navigate(retainConfig({ pathname: "/recovered" }), { replace: true })
                })
                .catch((err: AxiosError<SelfServiceLoginFlow | JsonError>) => {
                    const data = err.response?.data
                    console.log("starting recovery failed", err)
                    if (data && "ui" in data) {
                        showErrors(extractMessages(data.ui))
                    } else if (data && "error" in data && data.error.reason) {
                        showErrors([data.error.reason])
                    } else {
                        showErrors([err.message])
                    }
                })
                .catch((err) => {
                    console.log("starting recovery has failed for unknown reason", err)
                    showErrors([
                        "Password recovery has failed. Please try again or contact our support at support@eyetowers.io.",
                    ])
                })
        },
        [ory]
    )

    const logout = useCallback(
        (navigate: NavigateFunction) => {
            setLoading(true)

            ory.createSelfServiceLogoutFlowUrlForBrowsers()
                .then((response) => ory.submitSelfServiceLogoutFlow(response.data.logout_token))
                .then(() => {
                    setWhoAmI(undefined)
                    navigate(retainConfig({ pathname: "/login" }))
                })
                .catch((err) => console.log("loggout failed", err))
                .finally(() => setLoading(false))
        },
        [ory]
    )

    return (
        <AuthContext.Provider value={{ whoAmI, expired, loading, login, setPassword, startRecovery, logout }}>
            {loadingInitial ? <Typography>Loading user data...</Typography> : children}
        </AuthContext.Provider>
    )
}

const useAuth = () => useContext(AuthContext)
export default useAuth
