import {
    BatterySaver,
    KeyboardArrowDown,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardArrowUp,
    ManageSearch,
    NoEncryption,
    NotificationImportantOutlined,
    NotificationsActiveOutlined,
    NotificationsOutlined,
} from "@mui/icons-material"
import { Button, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { useConfirm } from "material-ui-confirm"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { useEffectOnceWhen, useKey } from "rooks"
import useSound from "use-sound"
import { ItemState } from "../../api/Alarm"
import { asUnitSubject, Operation } from "../../api/Authz"
import { CameraConfig, Site, Unit } from "../../api/Customer"
import {
    Event,
    EventType,
    OnvifCellMotionDetector,
    OnvifFaceDetector,
    OnvifFieldDetectorInside,
    OnvifFieldDetectorOutside,
    OnvifHumanDetector,
    OnvifLineDetector,
    OnvifLoiteringDetector,
    OnvifMotionAlarm,
    OnvifMotionDetector,
    OnvifObjectDetectorLeft,
    OnvifObjectDetectorRemoved,
    OnvifTamperDetector,
    PartitionAlarm,
} from "../../api/Event"
import { CurrentTime, IsLive, Live, Playback } from "../../api/Video"
import useAuthorizer, { useAnyUnitsCamerasPermission, useAnyUnitsPermission } from "../../auth/AuthorizerProvider"
import { http } from "../../backend/request"
import { runIfAudioLocked } from "../../config/audio"
import { request } from "../../config/headers"
import {
    ActualCameraIndex,
    DefaultPageSize,
    EqualPaging,
    Paging,
    SafePaging,
    WithPage,
    WithPageSize,
    WithPinned,
} from "../../config/paging"
import { Before, CameraDelay, Hour, OpenFuture, PaddedRange } from "../../config/time"
import { endpointURL, staticURL } from "../../config/urls"
import { useEventFilter } from "../../hooks/eventfilter"
import { noAlarms, useFlags } from "../../hooks/flags"
import { Node } from "../../hooks/treeset"
import { Leaf, NewFilter } from "../../services/EventFilter"
import { allEvents, useEventStream } from "../../services/EventStream"
import { CamCountChoice } from "../common/CamCountChoice"
import { Timeline } from "../common/Timeline"
import { OneHourWindow } from "../common/TimelineChoice"
import { EventFilterDialog } from "../events/EventFilterDialog"
import { EventList } from "../events/EventList"
import { LabelledCamera } from "./LabelledCamera"
import { PagingButton } from "./PagingButton"

export interface UnitCamerasProps {
    site: Site
    units: Unit[]
    initialPlayback: Playback
    initialPaging: Paging
}

export const filterTree: Node<EventType> = {
    id: "dialog.event_filter.all_events",
    children: [
        {
            id: "dialog.event_filter.alarm_events",
            children: [Leaf(PartitionAlarm)],
        },
        {
            id: "dialog.event_filter.camera_events",
            children: [
                Leaf(OnvifMotionAlarm),
                Leaf(OnvifMotionDetector),
                Leaf(OnvifCellMotionDetector),
                Leaf(OnvifFieldDetectorInside),
                Leaf(OnvifFieldDetectorOutside),
                Leaf(OnvifLineDetector),
                Leaf(OnvifHumanDetector),
                Leaf(OnvifFaceDetector),
                Leaf(OnvifLoiteringDetector),
                Leaf(OnvifObjectDetectorLeft),
                Leaf(OnvifObjectDetectorRemoved),
                Leaf(OnvifTamperDetector),
            ],
        },
    ],
}

const defaultFilter = NewFilter(false, new Set([PartitionAlarm.id]), [], [])

const updateNumberParam = (key: string, value: number, defaultValue: number, params: URLSearchParams) => {
    if (value === defaultValue) {
        params.delete(key)
    } else {
        params.set(key, `${value}`)
    }
}

const updateArrayParam = (key: string, values: number[], params: URLSearchParams) => {
    params.delete(key)
    values.map((v) => `${v + 1}`).forEach((v) => params.append(key, v))
}

const asDefaults = (recommendedPageSize: number, allowedPages: Map<number, number>) => {
    return {
        recommendedPageSize: recommendedPageSize,
        allowedSizes: Array.from(allowedPages.keys()),
        columns: allowedPages,
    }
}

const alarmFilterKey = (site: Site, units: Unit[]) => {
    if (units.length > 1) {
        return `site-${site.ID}/alarm-filter`
    }
    return `unit-${units[0].ID}/alarm-filter`
}

interface Camera {
    unit: Unit
    camera: CameraConfig
}

const cameraKey = (c: Camera) => `${c.unit.ShortName}/${c.camera.ID}`

const onvifEventCameraKey = (name: string) => name.replace(/^([a-zA-Z]+[0-9]+)\.camera_([0-9]+).onvif_events$/, "$1/$2")

export function UnitCameras(props: UnitCamerasProps) {
    const { site, units, initialPlayback, initialPaging } = props
    const { t } = useTranslation()
    const [, setSearchParams] = useSearchParams()

    const [paging, setPaging] = useState(initialPaging)
    const [playback, setPlayback] = useState<Playback>(initialPlayback)
    const [history, setHistory] = useState(!IsLive(initialPlayback))
    const [events, setEvents] = useState(false)
    const [eventsPlayback, setEventsPlayback] = useState(false)
    const [camAlarm, setCamAlarm] = useState(false)
    const [perimeterAlarm, setPerimeterAlarm] = useState(false)
    const [window, setWindow] = useState(OneHourWindow)

    const [filterOpen, setFilterOpen] = useState(false)
    const notifications = useEventFilter(alarmFilterKey(site, units), defaultFilter)

    const { setFlag: setCamFlag, clearFlag: clearCamFlag, getFlags: getCamFlags } = useFlags<string>()
    const { setFlag: setUnitFlag, hasFlag: hasUnitFlag, clearFlag: clearUnitFlag } = useFlags<string>()
    const confirm = useConfirm()
    const snackbar = useSnackbar()
    const { allowOperation } = useAuthorizer()
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))
    const isPortrait = useMediaQuery("(orientation: portrait)")
    const allowEvents = useAnyUnitsPermission(Operation.UI_VIEW_EVENTS, units)
    const allowDownload = useAnyUnitsCamerasPermission(Operation.EXPORT_CAMERA_ARCHIVE, units)
    const multipleUnits = units.length > 1

    const cameras = useMemo(
        () => units.flatMap((u) => u.UnitConfig.Cameras.map((c) => ({ unit: u, camera: c }))),
        [units]
    )

    const requiredRange = useMemo(
        () => (IsLive(playback) ? OpenFuture(Before(24 * Hour)) : PaddedRange(CurrentTime(playback), 12 * Hour)),
        [playback]
    )

    const { recommendedPageSize, allowedSizes, columns } = useMemo(() => {
        if (isPortrait) {
            return isSmall
                ? asDefaults(
                      2,
                      new Map([
                          [1, 1],
                          [2, 1],
                          [4, 1],
                      ])
                  )
                : asDefaults(
                      4,
                      new Map([
                          [1, 1],
                          [2, 1],
                          [4, 2],
                          [16, 2],
                      ])
                  )
        }
        return isSmall
            ? asDefaults(
                  2,
                  new Map([
                      [1, 1],
                      [2, 2],
                      [4, 2],
                      [9, 3],
                  ])
              )
            : asDefaults(
                  4,
                  new Map([
                      [1, 1],
                      [2, 2],
                      [4, 2],
                      [9, 3],
                      [16, 4],
                  ])
              )
    }, [isSmall, isPortrait])

    const updatePaging = useCallback(
        (updated: Paging) => {
            const sanitized = SafePaging(
                updated.PageSize,
                recommendedPageSize,
                allowedSizes,
                updated.Page,
                updated.Count,
                updated.Pinned
            )
            if (EqualPaging(paging, sanitized)) {
                // No relevant change.
                return
            }
            setPaging(sanitized)
            setSearchParams(
                (params) => {
                    const defaultPageSize = DefaultPageSize(recommendedPageSize, allowedSizes, sanitized.Count)
                    updateNumberParam("size", sanitized.PageSize, defaultPageSize, params)
                    updateNumberParam("page", sanitized.Page, 0, params)
                    updateArrayParam("lock", sanitized.Pinned, params)
                    return params
                },
                { replace: true }
            )
        },
        [setSearchParams, paging, allowedSizes, recommendedPageSize]
    )

    useEffect(() => {
        updatePaging(paging)
    }, [paging, updatePaging])

    const updatePlayback = useCallback(
        (playback: Playback) => {
            setPlayback(playback)
            setSearchParams(
                (params) => {
                    if (IsLive(playback)) {
                        params.delete("timestamp")
                    } else {
                        params.set("timestamp", `${CurrentTime(playback)}`)
                    }
                    return params
                },
                { replace: true }
            )
        },
        [setSearchParams]
    )

    const toggleHistory = () => {
        if (history) {
            updatePlayback(Live())
            setHistory(false)
        } else {
            setHistory(true)
        }
    }

    const [camAlarmSound] = useSound(staticURL("sound-alarm-motion.mp3"))
    const [perimeterAlarmSound] = useSound(staticURL("sound-alarm-perimeter.mp3"))

    const perimeterAlarmEnabled = useMemo(
        () => notifications.filter.enabled && notifications.filter.ids.has(PartitionAlarm.id),
        [notifications.filter]
    )

    const camAlarmEnabled = useCallback(
        (event: string) => notifications.filter.enabled && notifications.filter.ids.has(`onvif_events:${event}:1`),
        [notifications.filter]
    )

    const activateCamAlarm = useCallback(() => {
        setCamAlarm(true)
        camAlarmSound()
        snackbar.enqueueSnackbar(t("event.motion_detected_message", { time: new Date().toLocaleTimeString() }), {
            variant: "error",
            persist: true,
            action: (key) => (
                <Button variant="contained" color="error" onClick={() => snackbar.closeSnackbar(key)}>
                    Acknowledge
                </Button>
            ),
        })
    }, [camAlarmSound, snackbar, t])

    const deactivateCamAlarm = useCallback(() => setCamAlarm(false), [])

    const activatePerimeterAlarm = useCallback(() => {
        setPerimeterAlarm(true)
        perimeterAlarmSound()
        snackbar.enqueueSnackbar(t("event.perimeter_alarm_message", { time: new Date().toLocaleTimeString() }), {
            variant: "error",
            persist: true,
            action: (key) => (
                <Button variant="contained" color="error" onClick={() => snackbar.closeSnackbar(key)}>
                    Acknowledge
                </Button>
            ),
        })
    }, [perimeterAlarmSound, snackbar, t])

    const deactivatePerimeterAlarm = useCallback(() => setPerimeterAlarm(false), [])

    const onMessage = useCallback(
        (event: Event) => {
            const unit = event.labels.get("unit") || "unknown"
            if (event.item === "alarm" && event.thing.endsWith("alarm_1.partition_2")) {
                if (event.value === 1) {
                    setUnitFlag(unit, "perimeter_alarm")
                } else {
                    clearUnitFlag(unit, "perimeter_alarm")
                }
            } else if (event.item === "armed" && event.thing.endsWith("alarm_1.partition_2")) {
                if (event.value === 1) {
                    setUnitFlag(unit, "perimeter_armed")
                } else {
                    clearUnitFlag(unit, "perimeter_armed")
                }
            } else if (event.item === "level1" && event.thing.endsWith("modes.power_saving")) {
                if (event.value === 1) {
                    setUnitFlag(unit, "power_save")
                } else {
                    clearUnitFlag(unit, "power_save")
                }
            } else if (event.thing.endsWith(".onvif_events")) {
                const key = onvifEventCameraKey(event.thing)
                if (event.value === 1) {
                    setCamFlag(key, event.item)
                } else {
                    clearCamFlag(key, event.item)
                }
            }
        },
        [setUnitFlag, clearUnitFlag, setCamFlag, clearCamFlag]
    )

    const powerSaveUnits = useMemo(
        () => units.filter((u) => hasUnitFlag(u.ShortName, "power_save")),
        [units, hasUnitFlag]
    )
    const perimeterArmedUnits = useMemo(
        () => units.filter((u) => hasUnitFlag(u.ShortName, "perimeter_armed")),
        [units, hasUnitFlag]
    )
    const perimeterAlarmUnits = useMemo(
        () =>
            perimeterAlarmEnabled
                ? units.filter(
                      (u) => hasUnitFlag(u.ShortName, "perimeter_alarm") && hasUnitFlag(u.ShortName, "perimeter_armed")
                  )
                : [],
        [units, hasUnitFlag, perimeterAlarmEnabled]
    )

    useEventStream(site.ID, multipleUnits ? undefined : units[0].ShortName, allowEvents, true, allEvents, onMessage)

    useEffect(() => {
        const hasAlarm = cameras.some(
            (c) =>
                perimeterArmedUnits.some((u) => c.unit.ID === u.ID) &&
                Array.from(getCamFlags(cameraKey(c))).some(camAlarmEnabled)
        )
        if (!camAlarm && hasAlarm) {
            activateCamAlarm()
        } else if (camAlarm && !hasAlarm) {
            deactivateCamAlarm()
        }
    }, [cameras, getCamFlags, perimeterArmedUnits, camAlarm, camAlarmEnabled, activateCamAlarm, deactivateCamAlarm])

    useEffect(() => {
        const hasAlarm = perimeterAlarmUnits.length > 0
        if (hasAlarm && !perimeterAlarm) {
            activatePerimeterAlarm()
        } else if (!hasAlarm && perimeterAlarm) {
            deactivatePerimeterAlarm()
        }
    }, [units, perimeterAlarmUnits, hasUnitFlag, perimeterAlarm, activatePerimeterAlarm, deactivatePerimeterAlarm])

    useEffectOnceWhen(() => {
        if (!notifications.filter.enabled) {
            return
        }
        runIfAudioLocked(() =>
            snackbar.enqueueSnackbar(t("event.browser_audio_disabled_message"), {
                variant: "warning",
                persist: true,
                action: (key) => (
                    <Button variant="contained" color="warning" onClick={() => snackbar.closeSnackbar(key)}>
                        Allow
                    </Button>
                ),
            })
        )
    })

    const mayPageUp = useCallback(() => {
        if (paging.Page > 0) {
            updatePaging(WithPage(paging, paging.Page - 1))
        }
    }, [paging, updatePaging])
    const mayPageDown = useCallback(() => {
        if (paging.Page < paging.Pages - 1) {
            updatePaging(WithPage(paging, paging.Page + 1))
        }
    }, [paging, updatePaging])
    useKey(["ArrowUp", "ArrowLeft", "PageUp"], mayPageUp)
    useKey(["ArrowDown", "ArrowRight", "PageDown"], mayPageDown)

    const togglePinned = useCallback(
        (i: number) => {
            const wasPinned = paging.Pinned.some((v) => v === i)
            if (wasPinned) {
                updatePaging(
                    WithPinned(
                        paging,
                        paging.Pinned.filter((v) => v !== i)
                    )
                )
            } else {
                updatePaging(WithPinned(paging, paging.Pinned.concat([i])))
            }
        },
        [paging, updatePaging]
    )

    const disablePowerSave = (units: Unit[]) => {
        if (!units) {
            return
        }
        const single = units.length === 1
        confirm({
            title: single
                ? t("confirm.power_save_unit_disable_title", { unit: units[0].ShortName })
                : t("confirm.power_save_units_disable_title", { units: units.map((u) => u.ShortName).join(", ") }),
            description: t("confirm.power_save_unit_disable_description"),
            confirmationText: t("action.disable"),
            cancellationText: t("action.cancel"),
            confirmationButtonProps: {
                color: "success",
            },
        })
            .then(() =>
                Promise.all(
                    units
                        .filter((unit) => allowOperation(Operation.DISABLE_POWER_SAVE, asUnitSubject(unit)))
                        .map((unit) =>
                            http<void>(
                                `Disabling unit ${unit.ShortName} power save`,
                                endpointURL(`units/${unit.ID}/state/power-save`),
                                snackbar,
                                {
                                    method: "PUT",
                                    headers: request.headers,
                                    body: JSON.stringify(ItemState.Off),
                                }
                            )
                        )
                ).catch((e) => console.log(e))
            )
            .catch(() => {})
    }

    return (
        <Stack
            height="100%"
            py={1}
            px={0.5}
            sx={{
                alignItems: "center",
                backgroundColor: camAlarm || perimeterAlarm ? "#990000" : undefined,
            }}
            spacing={0.5}
        >
            <Stack
                direction={isPortrait ? "column" : "row"}
                alignItems="center"
                position="relative"
                width="100%"
                flexGrow={1}
                spacing={0.5}
            >
                <Stack
                    direction={isPortrait ? "row" : "column"}
                    alignItems="center"
                    alignContent="center"
                    spacing={0.5}
                    height={isPortrait ? undefined : "100%"}
                    width={isPortrait ? "100%" : undefined}
                    sx={{
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: theme.palette.panel.border,
                        backgroundColor: theme.palette.panel.background,
                    }}
                    p={0.5}
                >
                    <Stack flexGrow={1} direction={isPortrait ? "row" : "column"}>
                        <Tooltip title={t("dialog.notification_filter.tooltip")} disableInteractive>
                            <Box>
                                <PagingButton
                                    icon={<NotificationsActiveOutlined />}
                                    onClick={() => setFilterOpen(true)}
                                    color={notifications.filter.enabled ? "warning" : undefined}
                                />
                            </Box>
                        </Tooltip>
                        <Tooltip title={t(events ? "event.history_hide" : "event.history_show")} disableInteractive>
                            <Box>
                                <PagingButton
                                    icon={<ManageSearch />}
                                    disabled={!allowEvents}
                                    onClick={() => setEvents((v) => !v)}
                                    toggled={events}
                                />
                            </Box>
                        </Tooltip>
                    </Stack>
                    <Tooltip title={t("camera.number_per_page")} disableInteractive>
                        <Box>
                            <CamCountChoice
                                value={paging.PageSize}
                                choices={allowedSizes}
                                orientation={isPortrait ? "horizontal" : "vertical"}
                                onChange={(size) => updatePaging(WithPageSize(paging, size))}
                            />
                        </Box>
                    </Tooltip>
                    <Box minWidth={8} minHeight={8} />
                    {paging.Count > paging.PageSize && paging.Pages === 0 ? (
                        <Tooltip title={t("camera.remove_locks")} disableInteractive>
                            <Box>
                                <PagingButton
                                    icon={<NoEncryption />}
                                    onClick={() => updatePaging(WithPinned(paging, []))}
                                    color="warning"
                                />
                            </Box>
                        </Tooltip>
                    ) : (
                        <>
                            {paging.Pages > 1 && (
                                <>
                                    <PagingButton
                                        icon={isPortrait ? <KeyboardArrowLeft /> : <KeyboardArrowUp />}
                                        onClick={() => updatePaging(WithPage(paging, paging.Page - 1))}
                                        disabled={paging.Page <= 0}
                                    />
                                    <Typography variant="caption" fontWeight="bold">
                                        {paging.Page + 1}/{Math.max(paging.Pages, 1)}
                                    </Typography>
                                    <PagingButton
                                        icon={isPortrait ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
                                        onClick={() => updatePaging(WithPage(paging, paging.Page + 1))}
                                        disabled={paging.Page + 1 >= paging.Pages}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <Box flexGrow={1} />
                    {(powerSaveUnits.length > 0 || perimeterArmedUnits.length > 0) && (
                        <Stack flexGrow={0} direction={isPortrait ? "row" : "column"}>
                            {powerSaveUnits.length > 0 && (
                                <Tooltip
                                    title={t("state.power_saving_enabled_some", {
                                        units: powerSaveUnits.map((u) => u.ShortName).join(", "),
                                    })}
                                    disableInteractive
                                >
                                    {!!powerSaveUnits.find((u) =>
                                        allowOperation(Operation.DISABLE_POWER_SAVE, asUnitSubject(u))
                                    ) ? (
                                        <IconButton
                                            size="small"
                                            color="info"
                                            sx={{ width: 24, height: 24, padding: 0, margin: 0 }}
                                            onClick={() => disablePowerSave(powerSaveUnits)}
                                        >
                                            <BatterySaver color="warning" />
                                        </IconButton>
                                    ) : (
                                        <BatterySaver color="warning" />
                                    )}
                                </Tooltip>
                            )}
                            {perimeterArmedUnits.length > 0 && perimeterArmedUnits.length < units.length && (
                                <Tooltip
                                    title={t("state.units_armed_some", {
                                        units: perimeterArmedUnits.map((u) => u.ShortName).join(", "),
                                    })}
                                    disableInteractive
                                >
                                    <NotificationImportantOutlined htmlColor="orange" />
                                </Tooltip>
                            )}
                            {perimeterArmedUnits.length > 0 && perimeterArmedUnits.length === units.length && (
                                <Tooltip title={t("state.units_armed")} disableInteractive>
                                    <NotificationsOutlined htmlColor="red" />
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                </Stack>

                <Box
                    sx={{
                        display: "grid",
                        columnGap: 0.5,
                        rowGap: 0.5,
                        gridTemplateColumns: `repeat(${columns.get(paging.PageSize) || 1}, 1fr)`,
                    }}
                    width="100%"
                    height="100%"
                >
                    {Array.from({ length: paging.PageSize })
                        .map((_, i) => ActualCameraIndex(paging, i))
                        .map((i, pos) =>
                            i >= cameras.length ? (
                                <Box key={`cam-${i}`} width="100%" height="100%" />
                            ) : (
                                <LabelledCamera
                                    key={`cam-${i}`}
                                    camera={cameras[i].camera}
                                    site={site}
                                    unit={cameras[i].unit}
                                    displayUnit={multipleUnits}
                                    fitParent={true}
                                    preferFullscreen={isSmall}
                                    playback={playback}
                                    small={isSmall}
                                    toggledHistory={history}
                                    pinned={paging.Pinned.some((v) => v === i)}
                                    motionAlarms={IsLive(playback) ? getCamFlags(cameraKey(cameras[i])) : noAlarms}
                                    hasEvents={events}
                                    eventsPlayback={eventsPlayback}
                                    window={window}
                                    delay={CameraDelay(pos)}
                                    onWindowChange={setWindow}
                                    onSetEventsPlayback={setEventsPlayback}
                                    onPin={() => togglePinned(i)}
                                    onHistory={toggleHistory}
                                    onPlaybackChange={updatePlayback}
                                />
                            )
                        )}
                </Box>

                {allowEvents && events && (
                    <EventList
                        key="event-list"
                        site={site}
                        units={units}
                        isPortrait={isPortrait}
                        requiredRange={requiredRange}
                        playback={playback}
                        onPlayback={(playback) => {
                            setHistory(true)
                            updatePlayback(playback)
                        }}
                        eventsPlayback={eventsPlayback && events}
                    />
                )}
            </Stack>
            {history && (
                <Timeline
                    playback={playback}
                    small={isSmall}
                    hasEvents={events}
                    eventsPlayback={eventsPlayback}
                    window={window}
                    site={site}
                    units={units}
                    allowDownload={allowDownload}
                    onWindowChange={setWindow}
                    onSetEventsPlayback={setEventsPlayback}
                    onPlaybackChange={updatePlayback}
                    onClose={toggleHistory}
                />
            )}
            {filterOpen && (
                <EventFilterDialog
                    defaultFilter={defaultFilter}
                    filterTree={filterTree}
                    initialFilter={notifications.filter}
                    dialogTitle={t("dialog.notification_filter.title")}
                    enableLabel={t("dialog.notification_filter.enabled")}
                    disableEmpty
                    onChanged={(filter) => {
                        setFilterOpen(false)
                        notifications.update(filter)
                    }}
                    onClose={() => setFilterOpen(false)}
                />
            )}
        </Stack>
    )
}
